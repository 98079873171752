.App-container,
.App-right-pane {
  height: 100%;
}

.App-left-pane {
  width: 360px;
  height: 100%;
  overflow: scroll;
  background-color: #f5f5f5;
  border-right: 1px solid #e9e9e9;
}
